import { AfterViewInit, Directive, ElementRef, Input, NgZone, OnDestroy } from '@angular/core';
import { NewAttribute } from '@shared/types/attribute.types';
import { AttributeUtil } from '@shared/utils/attribute.util';
import { debounceTime, distinctUntilChanged, filter, fromEvent, map, merge, share, startWith, Subscription, tap } from 'rxjs';
import { ContextVariableAutocompleteInitEvent, ContextVariableAutocompleteSelectionEvent } from '../services/context-variable-autocomplete-event';
import { ContextVariableAutocompleteService } from '../services/context-variable-autocomplete.service';

type SupportAutoCompleteType = HTMLInputElement | HTMLTextAreaElement;

@Directive({
  selector: '[appContextVariableAutocomplete]',
})
export class ContextVariableAutocompleteDirective implements AfterViewInit, OnDestroy {
  static readonly DEBOUNCE = 250;
  static readonly ESCAPE_KEY = 'Escape';
  static readonly ENTER_KEY = 'Enter';
  static readonly ARROW_DOWN_KEY = 'ArrowDown';

  @Input() ownerId: string;
  @Input() attributes: NewAttribute[];
  @Input() variablePrefix = '{';
  @Input() variableSuffix = '}';

  event: KeyboardEvent;
  target?: HTMLElement;

  private initSubscription: Subscription;
  private caretPosition: number;
  private currentVariablePrefixIndex: number;

  constructor(
    private elementRef: ElementRef,
    private attributeUtil: AttributeUtil,
    private contextVariableAutocompleteService: ContextVariableAutocompleteService,
    private zone: NgZone,
  ) {}

  ngAfterViewInit(): void {
    if (this.elementRef) {
      this.zone.runOutsideAngular(() => {
        const selection$ = this.contextVariableAutocompleteService.autocompleteSelection$.pipe(
          filter(selectionEvent => selectionEvent.ownerId === this.ownerId),
          tap(selectionEvent => this.onVariableSelection(selectionEvent)),
        );

        const keyDown$ = fromEvent(this.elementRef.nativeElement, 'keydown').pipe(
          map(event => event as KeyboardEvent),
          filter(event => this.isDownKey(event)),
          tap(event => {
            event.preventDefault();
            event.stopPropagation();
          }),
        );

        const keypressBase$ = fromEvent(this.elementRef.nativeElement, 'keyup').pipe(
          map(event => event as KeyboardEvent),
          tap(event => (this.event = event)),
          share(),
        );

        const sourceValueBase$ = keypressBase$.pipe(
          filter(event => !this.isEscape(event) && !this.isEnter(event) && !this.isDownKey(event)),
          debounceTime(ContextVariableAutocompleteDirective.DEBOUNCE),
          map(() => this.elementRef.nativeElement.value as string),
          share(),
        );

        const sourceValueEmpty$ = sourceValueBase$.pipe(
          map(query => !query),
          startWith(true),
          distinctUntilChanged(),
          tap(isEmpty => isEmpty && this.doCloseOverlay()),
        );

        const keypressDown$ = keypressBase$.pipe(
          filter(event => this.elementRef.nativeElement.value && this.isDownKey(event)),
          tap(event => {
            event.preventDefault();
            event.stopPropagation();
          }),
          map(() => this.elementRef.nativeElement.value as string),
        );

        const invokeAutocompleteSelection$ = merge(keypressDown$, sourceValueBase$).pipe(
          tap(() => (this.caretPosition = (this.elementRef.nativeElement as SupportAutoCompleteType).selectionStart || 0)),
          map(query => this.isFocusInPotentialVariable(query, this.caretPosition)),
          tap(isFocusInPotentialVariable => !isFocusInPotentialVariable && this.doCloseOverlay()),
          filter(isFocusInPotentialVariable => !!this.caretPosition && isFocusInPotentialVariable),
          map(() => this.getVariableName(this.elementRef.nativeElement.value as string, this.caretPosition)),
          tap(variable => this.zone.run(() => this.doOpenOverlay(variable, this.isDownKey(this.event)))),
        );

        // filter((event: KeyboardEvent) => !KeyboardEventUtils.isEnterKey(event)),
        // map(() => this.inputElement.nativeElement.value),
        // debounceTime(PemaneConstants.COMMON_INPUT_DEBOUNCE_TIME),
        // share());

        // const selection$ = this.commonOverlayService.handleSelection$(ADVANCED_ACTIVITY_OVERLAY_OWNER).pipe(
        //     tap((selectedValueEvent) => {
        //         this.zone.run(() => {
        //             this.resetInput();
        //             this.onSelectExistingActivity.emit(selectedValueEvent.selectedValue);
        //         });
        //     }));

        // const keypressDown$ = keypressBase$.pipe(
        //     filter((event: KeyboardEvent) => KeyboardEventUtils.isUpOrDownKey(event)),
        //     tap(() => {
        //         this.zone.run(() => {
        //             const query = this.inputElement.nativeElement.value;
        //             this.doOpenOverlay(query, true);
        //         });
        //     }));

        // const closeOverlay$ = keypressBase$.pipe(
        //     filter((event: KeyboardEvent) => KeyboardEventUtils.isEscapeKey(event)),
        //     tap(() => {
        //         this.zone.run(() => this.doCloseOverlay());
        //     }));

        // const keypressEnter$ = keypressBase$.pipe(
        //     filter((event: KeyboardEvent) => KeyboardEventUtils.isEnterKey(event)),
        //     map(() => this.inputElement.nativeElement.value),
        //     tap((query) => {
        //         this.zone.run(() => {
        //             if (query) {
        //                 this.resetInput();
        //                 this.onAddNewActivity.emit(query);
        //                 this.doCloseOverlay();
        //                 return;
        //             }
        //             this.doCloseOverlay();
        //         });
        //     }));

        this.initSubscription = merge(selection$, keyDown$, invokeAutocompleteSelection$, sourceValueEmpty$).subscribe();
      });
    }
  }

  ngOnDestroy(): void {
    this.initSubscription?.unsubscribe();
  }

  private doOpenOverlay(variableName: string, doFocusFirstItem?: boolean) {
    const initEvent: ContextVariableAutocompleteInitEvent = {
      attributes: this.attributes,
      ownerId: this.ownerId,
      variableNamePart: variableName,
      event: this.event,
      doFocusFirstItem,
      caretPositionInQuery: this.caretPosition,
    };
    this.contextVariableAutocompleteService.notifyAutocompleteInit(initEvent);
  }

  private onVariableSelection(selectionEvent: ContextVariableAutocompleteSelectionEvent) {
    const nativeElement = this.elementRef.nativeElement as SupportAutoCompleteType;
    const query = nativeElement.value;
    const caretPosition = selectionEvent.initEvent.caretPositionInQuery;
    const variableNamePart = selectionEvent.initEvent.variableNamePart;
    const variableIndex = this.getCurrentVariablePrefixIndex(query, caretPosition);
    const prefix = query.substring(0, variableIndex);
    const suffix = query.substring(variableIndex + variableNamePart.length + 1);
    const variableName = selectionEvent.selectedAttribute.alias;
    const result = prefix + this.variablePrefix + variableName + suffix;
    const updatedCaretPosition = result.length - suffix.length;
    nativeElement.value = result;
    nativeElement.focus();
    nativeElement.setSelectionRange(updatedCaretPosition, updatedCaretPosition);
  }

  private doCloseOverlay() {
    this.contextVariableAutocompleteService.notifyAutocompleteClose(this.ownerId);
  }

  private getCurrentVariablePrefixIndex(query: string, caretPosition: number): number {
    const queryToCaret = query.substring(0, caretPosition);
    return queryToCaret.lastIndexOf(this.variablePrefix);
  }

  private getVariableName(query: string, caretPosition: number): string {
    const variablePrefixIndex = this.getCurrentVariablePrefixIndex(query, caretPosition);
    const variableSuffixIndex = query.indexOf(this.variableSuffix, variablePrefixIndex);
    if (variableSuffixIndex > 0) {
      const candidate = query.substring(variablePrefixIndex + 1, variableSuffixIndex);
      const indexOfCandidatePrefix = candidate.indexOf(this.variablePrefix);
      return indexOfCandidatePrefix > 0 ? candidate.substring(0, indexOfCandidatePrefix) : candidate;
    }
    return query.substring(variablePrefixIndex + 1);
  }

  private isFocusInPotentialVariable(query: string, caretPosition: number): boolean {
    const queryToCaret = query.substring(0, caretPosition);
    const variablePrefixIndex = queryToCaret.lastIndexOf(this.variablePrefix);
    const variableSuffixIndex = queryToCaret.lastIndexOf(this.variableSuffix);
    return variablePrefixIndex >= 0 && variableSuffixIndex < variablePrefixIndex;
  }

  private isDownKey(event: KeyboardEvent): boolean {
    return event.key === ContextVariableAutocompleteDirective.ARROW_DOWN_KEY;
  }

  private isEscape(event: KeyboardEvent): boolean {
    return event.key === ContextVariableAutocompleteDirective.ESCAPE_KEY;
  }

  private isEnter(event: KeyboardEvent): boolean {
    return event.key === ContextVariableAutocompleteDirective.ENTER_KEY;
  }
}
