import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WorkflowActionCalculateAttributeValues } from '@workflows/types';
import { Subscription } from 'rxjs';
import { AbstractWorkflowRuleItemComponent } from '../../abstract';

@Component({
  selector: 'app-rule-action-calculate',
  templateUrl: 'action-calculate.component.html',
  styleUrls: ['./action-calculate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionCalculateComponent extends AbstractWorkflowRuleItemComponent implements OnChanges, OnDestroy {
  @Input() action: WorkflowActionCalculateAttributeValues;

  actionValid: boolean;
  private inputSubscription: Subscription;

  constructor(
    protected translateService: TranslateService,
    private cdr: ChangeDetectorRef,
  ) {
    super(translateService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.action?.currentValue) {
      this.actionValid = this.action.isValid();
      if (this.action.isAttributeRequired() && !this.action.calculationAttributeIds?.length) {
        this.adjustCalculationAttributeIds();
      }
    }
  }

  ngOnDestroy(): void {
    this.inputSubscription?.unsubscribe();
  }

  onCalculationAttributeChange(attributeId: string, index: number) {
    const calculationAttributeIds = this.action.calculationAttributeIds;
    this.action.calculationAttributeIds = calculationAttributeIds?.map((attrId, attrIndex) => (attrIndex === index ? attributeId : attrId));
    this.cdr.markForCheck();
  }

  onExpressionValidation(isValid: boolean) {
    this.actionValid = isValid;
    if (this.actionValid) {
      const variableNames = (this.action.expression && WorkflowActionCalculateAttributeValues.extractExpressionVariables(this.action.expression)) || [];
      if (variableNames?.length) {
        this.action.calculationAttributeIds = variableNames.map(name => this.selectedAttributes.find(attr => attr.alias === name)?.id) as string[];
      }
    }
  }

  onAttributeChange(attributeId: string) {
    this.action.attributeId = attributeId;
  }

  addNewCalculationAttribute(index: number) {
    const calculationAttributeIds = this.action.calculationAttributeIds;
    if (!calculationAttributeIds?.includes('')) {
      calculationAttributeIds?.splice(index + 1, 0, '');
      this.action.calculationAttributeIds = [...calculationAttributeIds];
    }
  }

  private adjustCalculationAttributeIds() {
    if (this.action.isCalculatedValueType()) {
      this.action.calculationAttributeIds = [''];
    }
  }
}
