import { inject } from '@angular/core';
import { ApplicationResponseDto } from '@api/models/application-response-dto';
import { ArtifactLinkResponseDto } from '@api/models/artifact-link-response-dto';
import { ArtifactTypeResponseDto } from '@api/models/artifact-type-response-dto';
import { AttributeResponseDto } from '@api/models/attribute-response-dto';
import { DataTypeResponseDto } from '@api/models/data-type-response-dto';
import { LinkTypeResponseDto } from '@api/models/link-type-response-dto';
import { PageResponseDto } from '@api/models/page-response-dto';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { SystemUserTenantApplicationResponseDto } from '@api/models/system-user-tenant-application-response-dto';
import { TeamResponseDto } from '@api/models/team-response-dto';
import { TemplateResponseDto } from '@api/models/template-response-dto';
import { UserFullResponseDto } from '@api/models/user-full-response-dto';
import { UserResponseDto } from '@api/models/user-response-dto';
import {
  TenantApplicationService,
  TenantArtifactService,
  TenantArtifactTypeService,
  TenantAttributeService,
  TenantDataTypeService,
  TenantLinkTypeService,
  TenantPageService,
  TenantTeamService,
  TenantTemplateService,
  TenantUserService,
  TenantWidgetService,
} from '@api/services';
import { Constants } from '@shared/constants/constants';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { StateKey } from '@shared/types/local-storage.types';
import { WidgetResponseDto } from '@shared/types/widget.types';
import { lastValueFrom, map, Observable } from 'rxjs';
import { CachedUserMeta, CacheListResponseDto } from '../types/new-cached-subject.types';
import { DbEntityCachedData } from '../utils/db-entity-cached-data.subject';
import { NewCachedSubject } from '../utils/new-cached-subject';

export class NewAppCache {
  loaded = false;
  user: NewCachedSubject<SelfUserResponseDto>;
  userMeta: CachedUserMeta = new CachedUserMeta();
  data: NewAppCacheData;

  private readonly _onLoaded?: () => void;
  private _tenantUserService = inject(TenantUserService);
  private _localStorageService = inject(LocalStorageService);

  constructor(onLoaded?: () => void) {
    this._onLoaded = onLoaded;
    this.data = new NewAppCacheData();
  }

  initCache(): void {
    this.initUserWithMeta();
    this.data.initTenantData(() => this.raceResolver());
  }

  private initUserWithMeta(): void {
    this.user = new NewCachedSubject<SelfUserResponseDto>({
      updateFn: () => this._tenantUserService.userControllerGetInfo(),
      onUpdate: entity => {
        this.updateUserMeta(entity as SelfUserResponseDto);
        this.raceResolver();
      },
      callUpdateOnInit: true,
      localStorageMeta: {
        localStorageService: this._localStorageService,
        key: Constants.user,
        stateKey: StateKey.session,
        storeOnlyValue: true,
      },
    });
  }

  private updateUserMeta(user: SelfUserResponseDto): void {
    if (!user) return;

    this.userMeta.isSystemAdmin = user.isSystemAdmin;
    this.userMeta.isTenantAdmin = !!user.tenant?.isAdmin;
    this.userMeta.isApplicationAdmin = user.tenant
      ? user.tenant.applications.some((application: SystemUserTenantApplicationResponseDto) => application.isAdmin)
      : false;
  }

  private raceResolver(): void {
    if (
      this.user?.loaded &&
      this.user?.value &&
      this.data?.users?.loaded &&
      this.data?.teams?.loaded &&
      this.data?.applications?.loaded &&
      this.data?.artifactTypes?.loaded &&
      this.data?.linkTypes?.loaded &&
      this.data?.attributes?.loaded &&
      this.data?.dataTypes?.loaded &&
      this.data?.pages?.loaded &&
      this.data?.artifacts?.loaded &&
      this.data?.templates?.loaded
    ) {
      this._onLoaded?.();
    }
  }
}

export class NewAppCacheData {
  users: DbEntityCachedData<UserResponseDto | UserFullResponseDto>;
  teams: DbEntityCachedData<TeamResponseDto>;
  applications: DbEntityCachedData<ApplicationResponseDto>;
  artifactTypes: DbEntityCachedData<ArtifactTypeResponseDto>;
  linkTypes: DbEntityCachedData<LinkTypeResponseDto>;
  attributes: DbEntityCachedData<AttributeResponseDto>;
  dataTypes: DbEntityCachedData<DataTypeResponseDto>;
  pages: DbEntityCachedData<PageResponseDto>;
  artifacts: DbEntityCachedData<ArtifactLinkResponseDto>;
  templates: DbEntityCachedData<TemplateResponseDto>;
  widgets: DbEntityCachedData<WidgetResponseDto>;

  private _tenantUserService = inject(TenantUserService);
  private _tenantTeamService = inject(TenantTeamService);
  private _tenantApplicationService = inject(TenantApplicationService);
  private _tenantArtifactTypeService = inject(TenantArtifactTypeService);
  private _tenantLinkTypeService = inject(TenantLinkTypeService);
  private _tenantAttributeService = inject(TenantAttributeService);
  private _tenantDataTypeService = inject(TenantDataTypeService);
  private _tenantPageService = inject(TenantPageService);
  private _tenantArtifactService = inject(TenantArtifactService);
  private _tenantTemplateService = inject(TenantTemplateService);
  private _tenantWidgetService = inject(TenantWidgetService);

  async initTenantData(raceResolver: () => void): Promise<void> {
    await Promise.all([
      this.initUsers(raceResolver),
      this.initTeams(raceResolver),
      this.initApplications(raceResolver),
      this.initArtifactTypes(raceResolver),
      this.initLinkTypes(raceResolver),
      this.initAttributes(raceResolver),
      this.initDataTypes(raceResolver),
      this.initPages(raceResolver),
      this.initTemplates(raceResolver),
    ]);

    this.initArtifacts(raceResolver);
    this.initWidgets(raceResolver);
  }

  private async initUsers(raceResolver: () => void): Promise<void> {
    const initialValue = (await lastValueFrom(this._tenantUserService.userControllerList())).data;
    this.users = new DbEntityCachedData({
      updateFns: {
        get: (id: string) => this._tenantUserService.userControllerGet({ id }),
        list: filter => this.processListRequest(this._tenantUserService.userControllerList({ filter })),
      },
      initialValue,
      callUpdateOnInit: true,
      onUpdate: () => raceResolver(),
    });
  }

  private async initTeams(raceResolver: () => void): Promise<void> {
    const initialValue = (await lastValueFrom(this._tenantTeamService.teamControllerList())).data;
    this.teams = new DbEntityCachedData<TeamResponseDto>({
      updateFns: {
        get: (id: string) => this._tenantTeamService.teamControllerGet({ id }),
        list: filter => this.processListRequest(this._tenantTeamService.teamControllerList({ filter })),
      },
      initialValue,
      callUpdateOnInit: true,
      onUpdate: () => raceResolver(),
    });
  }

  private async initApplications(raceResolver: () => void): Promise<void> {
    const initialValue = (await lastValueFrom(this._tenantApplicationService.applicationControllerList())).data;
    this.applications = new DbEntityCachedData<ApplicationResponseDto>({
      updateFns: {
        get: (id: string) => this._tenantApplicationService.applicationControllerGet({ id }),
        list: filter => this.processListRequest(this._tenantApplicationService.applicationControllerList({ filter })),
      },
      initialValue,
      callUpdateOnInit: true,
      onUpdate: () => raceResolver(),
    });
  }

  private async initArtifactTypes(raceResolver: () => void): Promise<void> {
    const initialValue = (await lastValueFrom(this._tenantArtifactTypeService.artifactTypeControllerList())).data;
    this.artifactTypes = new DbEntityCachedData<ArtifactTypeResponseDto>({
      updateFns: {
        get: (id: string) => this._tenantArtifactTypeService.artifactTypeControllerGet({ id }),
        list: filter => this.processListRequest(this._tenantArtifactTypeService.artifactTypeControllerList({ filter })),
      },
      initialValue,
      callUpdateOnInit: true,
      onUpdate: () => raceResolver(),
    });
  }

  private async initLinkTypes(raceResolver: () => void): Promise<void> {
    const initialValue = (await lastValueFrom(this._tenantLinkTypeService.linkTypeControllerList())).data;
    this.linkTypes = new DbEntityCachedData<LinkTypeResponseDto>({
      updateFns: {
        get: (id: string) => this._tenantLinkTypeService.linkTypeControllerGet({ id }),
        list: filter => this.processListRequest(this._tenantLinkTypeService.linkTypeControllerList({ filter })),
      },
      initialValue,
      callUpdateOnInit: true,
      onUpdate: () => raceResolver(),
    });
  }

  private async initAttributes(raceResolver: () => void): Promise<void> {
    const initialValue = (await lastValueFrom(this._tenantAttributeService.attributeControllerList())).data;
    this.attributes = new DbEntityCachedData<AttributeResponseDto>({
      updateFns: {
        get: (id: string) => this._tenantAttributeService.attributeControllerGet({ id }),
        list: filter => this.processListRequest(this._tenantAttributeService.attributeControllerList({ filter })),
      },
      initialValue,
      callUpdateOnInit: true,
      onUpdate: () => raceResolver(),
    });
  }

  private async initDataTypes(raceResolver: () => void): Promise<void> {
    const initialValue = (await lastValueFrom(this._tenantDataTypeService.dataTypeControllerList())).data;
    this.dataTypes = new DbEntityCachedData<DataTypeResponseDto>({
      updateFns: {
        get: (id: string) => this._tenantDataTypeService.dataTypeControllerGet({ id }),
        list: filter => this.processListRequest(this._tenantDataTypeService.dataTypeControllerList({ filter })),
      },
      initialValue,
      callUpdateOnInit: true,
      onUpdate: () => raceResolver(),
    });
  }

  private async initPages(raceResolver: () => void): Promise<void> {
    const initialValue = (await lastValueFrom(this._tenantPageService.pageControllerList())).data;
    this.pages = new DbEntityCachedData<PageResponseDto>({
      updateFns: {
        get: (idAlias: string) => this._tenantPageService.pageControllerGet({ idAlias }),
        list: filter => this.processListRequest(this._tenantPageService.pageControllerList({ filter })),
      },
      initialValue,
      callUpdateOnInit: true,
      onUpdate: () => raceResolver(),
      additionalKeysForIndexing: ['alias'],
    });
  }

  private initArtifacts(raceResolver: () => void): void {
    this.artifacts = new DbEntityCachedData<ArtifactLinkResponseDto>({
      updateFns: {
        get: (id: string) => this._tenantArtifactService.artifactControllerGet({ id }),
        list: filter => this._tenantArtifactService.artifactControllerList({ body: { filter } }).pipe(map(res => res.data)),
      },
      onUpdate: () => raceResolver(),
    });
  }

  private async initTemplates(raceResolver: () => void): Promise<void> {
    const initialValue = (await lastValueFrom(this._tenantTemplateService.templateControllerList())).data;
    this.templates = new DbEntityCachedData<TemplateResponseDto>({
      updateFns: {
        get: (id: string) => this._tenantTemplateService.templateControllerGet({ id }),
        list: filter => this.processListRequest(this._tenantTemplateService.templateControllerList({ filter })),
      },
      initialValue,
      callUpdateOnInit: true,
      onUpdate: () => raceResolver(),
    });
  }

  private initWidgets(raceResolver: () => void): void {
    this.widgets = new DbEntityCachedData<WidgetResponseDto>({
      updateFns: {
        get: (id: string) => this._tenantWidgetService.widgetControllerGet({ id }),
        list: filter => this.processListRequest(this._tenantWidgetService.widgetControllerList({ filter })),
      },
      onUpdate: () => raceResolver(),
    });
  }

  private processListRequest<T>(os$: Observable<CacheListResponseDto<T>>): Observable<Array<T>> {
    return os$.pipe(map(res => res.data));
  }
}
