import { BackgroundTypeEnum, BorderWidthEnum, ContainerClassEnum, OverflowEnum } from '../../shared/types/style.types';
import { CardWidgetStylesDto } from './card-widget-styles-dto';

export class CardWidgetStyles {
  containerClass: ContainerClassEnum = ContainerClassEnum.box;

  maxWidth: string;
  minWidth: string;

  borderTopWidth: BorderWidthEnum;
  borderRightWidth: BorderWidthEnum;
  borderBottomWidth: BorderWidthEnum;
  borderLeftWidth: BorderWidthEnum;

  borderTopStyle?: string;
  borderRightStyle?: string;
  borderBottomStyle?: string;
  borderLeftStyle?: string;

  borderTopColor?: string;
  borderRightColor?: string;
  borderBottomColor?: string;
  borderLeftColor?: string;

  borderTopLeftRadius = '4px';
  borderTopRightRadius = '4px';
  borderBottomRightRadius = '4px';
  borderBottomLeftRadius = '4px';
  borderRadiusRange: number[];
  isBorderEnable = false;

  boxShadow = '0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12)';

  backgroundType: BackgroundTypeEnum = BackgroundTypeEnum.color;
  backgroundColor = '#ffffff';
  backgroundImage?: string;
  background: string;
  gradient: string;
  gradientDirection: string;
  gradientColor1: string;
  gradientColor2: string;

  marginTop: string;
  marginRight: string;
  marginBottom: string;
  marginLeft: string;

  paddingTop: string;
  paddingRight: string;
  paddingBottom: string;
  paddingLeft: string;

  overflowY: OverflowEnum;
  overflowX: OverflowEnum;

  constructor(dto?: CardWidgetStylesDto) {
    Object.assign(this, dto);
  }
}
