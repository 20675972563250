<p-overlayPanel appendTo="body">
  <ng-template pTemplate>
    <!-- <div class="autocomplete-options-container">
      <div #navContainer *ngFor="let item of attributes" class="item">
        {{ item.name }}
      </div>
    </div> -->
    <p-listbox
      [listStyle]="{ 'max-height': '250px', width: '250px' }"
      [options]="attributes"
      dataKey="dataKey"
      [ngModel]="selectedAttribute"
      styleClass="{{ styleClass }}"
      (onClick)="onAttributeSelected($event.option)"
    >
      <ng-template let-option pTemplate="item">
        <span>{{ option.name }}</span>
      </ng-template>
    </p-listbox>
  </ng-template>
</p-overlayPanel>
