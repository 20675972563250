import { PageBlockPartRequestDto } from '@api/models/page-block-part-request-dto';
import { PageBlockPartResponseDto } from '@api/models/page-block-part-response-dto';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { PageBlockPartStyles } from '@private/pages/page-management/page-builder-graphical/types/page-block-part-styles';
import { ElvisUtil } from '@shared/utils/elvis.util';

export class PageBlockPart {
  hash: string;

  constructor(
    public widget: BlockPartWidget<any> | null = null,
    public styles: PageBlockPartStyles = new PageBlockPartStyles(),
    public templateId: string | null = null,
    public htmlId?: string,
  ) {
    this.hash = ElvisUtil.makeHash(20);
  }

  get requestDto(): PageBlockPartRequestDto {
    return {
      widget: this.widget
        ? {
            widgetId: this.widget.templateId ? null : this.widget.id,
            templateId: this.widget.templateId || null,
          }
        : {
            widgetId: null,
            templateId: null,
          },
      templateId: this.templateId,
      styles: this.styles,
      htmlId: this.htmlId,
    };
  }

  static fromDto(dto: PageBlockPartResponseDto): PageBlockPart {
    const styles = dto.styles ? new PageBlockPartStyles(dto.styles as any) : undefined;

    // @ts-ignore
    if (dto.widget?.widgetId || dto.widget?.templateId || dto.widget?.code) {
      const widget = new BlockPartWidget(dto.widget);
      return new PageBlockPart(widget, styles, dto.templateId, dto.htmlId);
    }

    return new PageBlockPart(null, styles, dto.templateId, dto.htmlId);
  }

  removeIdsForReuse(removeHtmlIds = false): void {
    removeHtmlIds && (this.htmlId = undefined);
    this.widget?.removeIdsForReuse();
  }
}
