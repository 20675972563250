<div *ngIf="isReadyToBeRendered" class="container artifact-widget-label artifact-widget_form">
  <div
    [ngClass]="{
      'float-label': model.settings.labelBehaviour === labelBehaviourEnum.float,
      'static-label': model.settings.labelBehaviour === labelBehaviourEnum.static,
      'placeholder-label': model.settings.labelBehaviour === labelBehaviourEnum.placeholder
    }"
  >
    <div class="row" [ngClass]="isInSavingProcess ? 'no-active': ''">
      <ng-container *ngFor="let item of model.form; let i = index">
        <div
          *ngIf="item.tabSettings | shouldShowArtifactTabAttribute : model.queryParams : model.isNoTabKeyActiveInUrl"
          [class]="'form-item-padded col-12 md:col-' + item?.columns"
          [ngStyle]="
            item?.attribute
              ? model.formatsMap.attribute[item?.attribute?.value?.id]?.outerArea
              : model.formatsMap.linkType[item?.linkType?.value?.id + '_' + item?.linkType?.meta]?.outerArea
          "
        >
          <div
            class="limit-width"
            [ngClass]="model.formatsMap.attribute[item?.attribute?.value?.id]?.label | labelClass : model.settings.labelBehaviour : false"
            [ngStyle]="
              item?.attribute
                ? model.formatsMap.attribute[item?.attribute?.value?.id]?.innerArea
                : model.formatsMap.linkType[item?.linkType?.value?.id + '_' + item?.linkType?.meta]?.innerArea
            "
            style="display: table-cell; width: 100%; position: relative"
          >
            <ng-container
              *ngIf="
                  !artifactWidgetCustomAttributeHelper.isLoginField(item?.attribute?.value?.id) &&
                  !artifactWidgetCustomAttributeHelper.isRecoverPasswordField(item?.attribute?.value?.id) &&
                  !artifactWidgetCustomAttributeHelper.isPasswordField(item?.attribute?.value?.id) &&
                  !artifactWidgetCustomAttributeHelper.isCustomAttributes(item?.attribute?.value?.id, model.selected.artifactType?.format) &&
                  model.formatsMap.attribute[item?.attribute?.value?.id]?.editable &&
                  !(item.attribute.value | getDataTypeFromClientAttribute : model.options.attributes : model.options.dataTypes)?.isFile  &&
                  (!model.settings.initiateEditWithEditButton || !model.artifactId || (model.settings.initiateEditWithEditButton && model.isEditInProgress));
                else readonlyAttribute
              "
            >
              <app-artifact-attribute-form-field
                #awFormField
                [clientAttribute]="item.attribute.value"
                [attribute]="item.attribute.value | getAttributeFromClientAttribute : model.options.attributes"
                [dataType]="item.attribute.value | getDataTypeFromClientAttribute : model.options.attributes : model.options.dataTypes"
                [users]="this.model.options.users"
                [changeFormFocus]="changeFormFocus.bind(this)"
                [formatSettings]="model.formatsMap.attribute[item?.attribute?.value?.id]"
                [index]="i"
                [labelBehaviour]="model.settings.labelBehaviour"
                [label]="
                  model.formatsMap.attribute[item?.attribute?.value?.id]?.label.customLabel
                    | getAttributeLabel : item.attribute.label : model.formatsMap.attribute[item?.attribute?.value?.id].label
                "
                [onBlurCb]="onFormBlur.bind(this)"
                [onChangeCb]="onFormChange.bind(this)"
                [folderId]="model.selected?.artifactType?.defaultFolderId"
                [placeholder]="model.settings.labelBehaviour | getItemPlaceholder : model.formatsMap.attribute[item?.attribute?.value?.id]?.value.placeholder"
                appShowHideAttribute
                appAttributeChangeValueNotify
                [pageId]="pageId"
                [artifactTypeId]="model.selected?.artifactType?.id"
                [attributeId]="(item.attribute.value | getAttributeFromClientAttribute : model.options.attributes)?.id"
                [widgetId]="widgetId"
                [getAttributeValueFn]="getAttributeValueFn.bind(this)"
                [artifact]="model.selected.artifact"
                appSetAttributeValue
                appModifyAttributeValue
                appSetAttributeOptionValues
                appSetAttributeMandatory
                [attributeOwner]="awFormField"
              >
              </app-artifact-attribute-form-field>
            </ng-container>
            <ng-template #readonlyAttribute>
              <ng-container
                *ngIf="
                  artifactWidgetCustomAttributeHelper.isCustomAttributes(item?.attribute?.value?.id, model.selected.artifactType?.format) &&
                  !artifactWidgetCustomAttributeHelper.isPasswordField(item?.attribute?.value?.id) &&
                  !artifactWidgetCustomAttributeHelper.isRecoverPasswordField(item?.attribute?.value?.id) &&
                  !artifactWidgetCustomAttributeHelper.isLoginField(item?.attribute?.value?.id)
                "
              >
                <app-artifact-widget-custom-field
                  [item]="item"
                  [ownerId]="hash"
                  [model]="model"
                  [formatSettings]="model.formatsMap.attribute[item?.attribute?.value?.id]"
                  [dataType]="item.attribute.value"
                  [artifactId]="model.artifactId"
                  [selected]="model.selected"
                  (openFolderPicker)="showPopupFolder($event)"
                  [labelBehaviour]="model.settings.labelBehaviour"
                >
                </app-artifact-widget-custom-field>
              </ng-container>
              <ng-container
                *ngIf="
                  !artifactWidgetCustomAttributeHelper.isPasswordField(item?.attribute?.value?.id) &&
                  !artifactWidgetCustomAttributeHelper.isLoginField(item?.attribute?.value?.id) &&
                  !artifactWidgetCustomAttributeHelper.isRecoverPasswordField(item?.attribute?.value?.id) &&
                  !artifactWidgetCustomAttributeHelper.isCustomAttributes(item?.attribute?.value?.id, model.selected.artifactType?.format) &&
                  item.attribute &&
                  !(item.attribute.value | getDataTypeFromClientAttribute : model.options.attributes : model.options.dataTypes)?.isFile
                "
              >
                <app-artifact-widget-readonly-field
                  #awFormReadonlyField
                  [artifactDto]="model.artifactDto"
                  [item]="item"
                  [ownerId]="hash"
                  [attributes]="model.options.attributes"
                  [formatSettings]="model.formatsMap.attribute[item?.attribute?.value?.id]"
                  [attr]="item.attribute.value"
                  [attribute]="item.attribute.value | getAttributeFromClientAttribute : model.options.attributes"
                  [dataType]="item.attribute.value | getDataTypeFromClientAttribute : model.options.attributes : model.options.dataTypes"
                  [model]="model"
                  [dataTypes]="model.options.dataTypes"
                  [selected]="model.selected"
                  [labelBehaviour]="model.settings.labelBehaviour"
                  appSetAttributeValue
                  appModifyAttributeValue
                  appShowHideAttribute
                  appSetAttributeMandatory
                  [pageId]="pageId"
                  [artifactTypeId]="model.selected?.artifactType?.id"
                  [attributeId]="(item.attribute.value | getAttributeFromClientAttribute : model.options.attributes)?.id"
                  [attributeOwner]="awFormReadonlyField"
                  appAttributeChangeValueNotify
                  [getAttributeValueFn]="getAttributeValueFn.bind(this)"
                >
                </app-artifact-widget-readonly-field>
              </ng-container>
            </ng-template>

            <app-file-attribute-value-edit-field
              #awFormFileField
              *ngIf="
                !artifactWidgetCustomAttributeHelper.isCustomAttributes(item?.attribute?.value?.id, model.selected.artifactType?.format) &&
                !artifactWidgetCustomAttributeHelper.isPasswordField(item?.attribute?.value?.id) &&
                !artifactWidgetCustomAttributeHelper.isRecoverPasswordField(item?.attribute?.value?.id) &&
                !artifactWidgetCustomAttributeHelper.isLoginField(item?.attribute?.value?.id) &&
                item.attribute &&
                (item.attribute.value | getDataTypeFromClientAttribute : model.options.attributes : model.options.dataTypes)?.isFile
              "
              [isMandatory]="item.attribute.value.isMandatory"
              [(fileValue)]="item.attribute.value.value"
              [isInSavingProcess]="isInSavingProcess"
              [attribute]="item.attribute.value | getAttributeFromClientAttribute : model.options.attributes"
              [formatSettings]="model.formatsMap.attribute[item?.attribute?.value?.id]"
              [index]="i"
              [isEditMode]="model.formatsMap.attribute[item?.attribute?.value?.id]?.editable"
              [isEnabled]="!model.artifactId || !model.settings.initiateEditWithEditButton || model.isEditInProgress"
              [label]="
                model.formatsMap.attribute[item?.attribute?.value?.id]?.label.customLabel
                  | getAttributeLabel : item.attribute.label : model.formatsMap.attribute[item?.attribute?.value?.id].label
              "
              [onChangeCb]="onFormChange.bind(this)"
              [attributeOwner]="awFormFileField"
              [artifactTypeId]="model.selected?.artifactType?.id"
              [attributeId]="(item.attribute.value | getAttributeFromClientAttribute : model.options.attributes)?.id"
              appShowHideAttribute
            >
            </app-file-attribute-value-edit-field>

            <app-artifact-widget-login-field
              *ngIf="artifactWidgetCustomAttributeHelper.isLoginField(item?.attribute?.value?.id)"
              [attr]="item.attribute.value"
              [index]="i"
              [labelBehaviour]="model.settings.labelBehaviour"
              [label]="
                model.formatsMap.attribute[item?.attribute?.value?.id]?.label.customLabel
                  | getAttributeLabel : item.attribute.label : model.formatsMap.attribute[item?.attribute?.value?.id].label
              "
              [onBlurCb]="onFormBlur.bind(this)"
              [onChangeCb]="onFormChange.bind(this)"
              [changeFormFocus]="changeFormFocus.bind(this)"
              [formatSettings]="model.formatsMap.attribute[item?.attribute?.value?.id]"
              [placeholder]="model.settings.labelBehaviour | getItemPlaceholder : model.formatsMap.attribute[item?.attribute?.value?.id]?.value.placeholder"
            >
            </app-artifact-widget-login-field>

            <app-artifact-widget-password-field
              *ngIf="artifactWidgetCustomAttributeHelper.isPasswordField(item?.attribute?.value?.id)"
              [attr]="item.attribute.value"
              [index]="i"
              [labelBehaviour]="model.settings.labelBehaviour"
              [label]="
                model.formatsMap.attribute[item?.attribute?.value?.id]?.label.customLabel
                  | getAttributeLabel : item.attribute.label : model.formatsMap.attribute[item?.attribute?.value?.id].label
              "
              [onBlurCb]="onFormBlur.bind(this)"
              [onChangeCb]="onFormChange.bind(this)"
              [changeFormFocus]="changeFormFocus.bind(this)"
              [formatSettings]="model.formatsMap.attribute[item?.attribute?.value?.id]"
              [placeholder]="model.settings.labelBehaviour | getItemPlaceholder : model.formatsMap.attribute[item?.attribute?.value?.id]?.value.placeholder"
            >
            </app-artifact-widget-password-field>

            <ng-container *ngIf="item?.linkType">
              <app-artifact-widget-link-field
                #linkField
                [hash]="hash"
                [linkAttributeId]="item.linkType.value | getLinkAttributeId : item.linkType.meta"
                [isLinkTypeRequired]="item.isLinkTypeRequired"
                [linkType]="item.linkType"
                [model]="model"
                [formatSettings]="model.formatsMap.linkType[item.linkType.value | getLinkAttributeId : item.linkType.meta]"
                [onAddLinkClick]="onAddLinkClick.bind(this)"
                appSetLinkValue
                [linkOwner]="linkField"
                (onExternalLinkedArtifactsToBeAdded)="onExternalLinkedArtifactsToBeAdded($event, item.linkType)"
              >
              </app-artifact-widget-link-field>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <div class="captcha">
        <re-captcha
          *ngIf="model.settings.useReCaptcha && ((model.settings.widgetType | isRecoverPasswordWidgetType) || (model.settings.widgetType | isRegistrationWidgetType))"
          (resolved)="handleReCaptcha($event)"
          (errored)="handleReCaptcha($event)">
        </re-captcha>
      </div>
    </div>

    <div class="row">
      <div class="col-12 button-group"
           [class.login-btn]="(model.settings.widgetType | isLoginWidgetType) || (model.settings.widgetType | isRecoverPasswordWidgetType)">
        <button
          *ngIf="
            model.selected.artifact?.id &&
            (model.settings.showHistoryButton || model.settings.showAclButton) &&
            !(model.settings.widgetType | isRegistrationWidgetType) &&
            !(model.settings.widgetType | isLoginWidgetType) &&
            !(model.settings.widgetType | isRecoverPasswordWidgetType)
          "
          pButton
          pRipple
          type="button"
          class="p-button p-button-rounded p-button-text"
          icon="pi pi-ellipsis-v"
          (click)="op.show($event)"
          style="width: 2.3rem"
        ></button>

        <button
          *ngIf="
            model.settings.initiateEditWithEditButton &&
            model.artifactId &&
            model.isEditInProgress &&
            !(model.settings.widgetType | isRegistrationWidgetType) &&
            !(model.settings.widgetType | isLoginWidgetType) &&
            !(model.settings.widgetType | isRecoverPasswordWidgetType)
          "
          (click)="onCancelEditClick()"
          class="p-button p-button-danger"
          label="{{ 'Cancel' | translate }}"
          pButton
          pRipple
        ></button>

        <button
          *ngIf="
            model.settings.initiateEditWithEditButton &&
            model.artifactId &&
            !model.isEditInProgress &&
            !(model.settings.widgetType | isRegistrationWidgetType) &&
            !(model.settings.widgetType | isLoginWidgetType) &&
            !(model.settings.widgetType | isRecoverPasswordWidgetType)
          "
          (click)="onEditClick()"
          class="p-button p-button-warning"
          label="{{ 'Edit' | translate }}"
          pButton
          pRipple
        ></button>

        <button
          *ngIf="
            model.showSaveButton &&
            (!model.artifactId || !model.settings.initiateEditWithEditButton || model.isEditInProgress) &&
            !(model.settings.widgetType | isRegistrationWidgetType) &&
            !(model.settings.widgetType | isLoginWidgetType) &&
            !(model.settings.widgetType | isRecoverPasswordWidgetType)
          "
          (click)="save(false)"
          [disabled]="(model.settings.saveButtonVisibility === saveVisibilityEnum.onChangeGreen && !model.hasFormChanged) || isInSavingProcess"
          [ngClass]="{ 'button-inactive': model.settings.saveButtonVisibility === saveVisibilityEnum.onChangeGreen && !model.hasFormChanged }"
          class="p-button p-button-success"
          label="{{ 'Save' | translate }}"
          pButton
          type="button"
        ></button>

        <button
          *ngIf="
            model.showSaveAndNotifyButton &&
            (!model.artifactId || !model.settings.initiateEditWithEditButton || model.isEditInProgress) &&
            !(model.settings.widgetType | isRegistrationWidgetType) &&
            !(model.settings.widgetType | isLoginWidgetType) &&
            !(model.settings.widgetType | isRecoverPasswordWidgetType)
          "
          (click)="save(true)"
          [disabled]="(model.settings.saveAndNotifyButtonVisibility === saveVisibilityEnum.onChangeGreen && !model.hasFormChanged) || isInSavingProcess"
          [ngClass]="{ 'button-inactive': model.settings.saveAndNotifyButtonVisibility === saveVisibilityEnum.onChangeGreen && !model.hasFormChanged }"
          class="p-button p-button-success"
          label="{{ 'Save and notify' | translate }}"
          pButton
          type="button"
        ></button>

        <button
          *ngIf="model.settings.widgetType | isRegistrationWidgetType"
          (click)="artifactWidgetService.register()"
          class="p-button p-button-success"
          label="{{ 'Register' | translate }}"
          pButton
          type="button"
        ></button>

        <button
          *ngIf="model.settings.widgetType | isLoginWidgetType"
          (click)="artifactWidgetService.login()"
          class="p-button p-button-success center"
          label="{{ 'Login' | translate }}"
          pButton
          type="button"
        ></button>

        <button
          *ngIf="model.settings.widgetType | isRecoverPasswordWidgetType"
          (click)="artifactWidgetService.recoverPassword()"
          class="p-button p-button-success center button-auto-width"
          label="{{ 'Recover password' | translate }}"
          pButton
          type="button"
        ></button>
      </div>
    </div>

    <span class="ssoBtns" *ngIf="model.settings.widgetType | isLoginWidgetType">
          <p-button *ngIf="model.settings.showGoogleSso"
                    pRipple
                    type="button"
                    styleClass="p-button-rounded p-button-icon-only"
                    (click)="googleSso.onClickHandler()"
                    [title]="googleSso.tooltip">
            <ng-template pTemplate="content"><div [outerHTML]="googleSso.content"></div></ng-template>
          </p-button>

          <p-button *ngIf="model.settings.showOpenIdSso"
                    pRipple
                    type="button"
                    styleClass="p-button-rounded p-button-icon-only"
                    (click)="openIdSso.onClickHandler()"
                    [title]="openIdSso.tooltip">
            <ng-template pTemplate="content"><div [outerHTML]="openIdSso.content"></div></ng-template>
          </p-button>
        </span>
  </div>
</div>

<app-artifact-history
  #artifactHistory
  [artifactTypes]="model.options.artifactTypes"
  [attributes]="model.options.attributes"
  [dataTypes]="model.options.dataTypes"
  [users]="model.options.users"
>
</app-artifact-history>

<app-folder-picker #folderPicker (changeFolder)="onChangeFolder($event)" [showConfirmation]="true"
                   [showPrivateFolder]="true"></app-folder-picker>

<p-overlayPanel #op appendTo="body">
  <ng-template pTemplate>
    <div class="table_buttons">
      <span
        *ngIf="model.settings.showHistoryButton && model.selected.artifact?.id"
        (click)="onShowHistoryClick()"
        [pTooltip]="'Show operation history' | translate"
        style="margin: 0 10px; font-size: 16px"
        class="bi bi-clock-history history-button cursor-pointer"
      >
      </span>
      <span
        *ngIf="model.settings.showAclButton"
        (click)="accessOverlay.show($event)"
        [pTooltip]="'Access rights' | translate"
        style="margin: 0 10px; font-size: 16px"
        class="pi pi-users cursor-pointer"
      >
      </span>
      <span (click)="deleteWithConfirmation()" [pTooltip]="'Remove' | translate" style="margin: 0 10px; font-size: 16px"
            class="pi pi-trash cursor-pointer">
      </span>
    </div>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #accessOverlay [style]="{ 'max-width': '380px', width: '361px' }" appendTo="body">
  <ng-template pTemplate>
    <app-acl [artifactId]="model.artifactId" (updateAcl)="accessOverlay.hide()"></app-acl>
  </ng-template>
</p-overlayPanel>
