import { Injectable } from '@angular/core';
import { ApplicationResponseDto, SelfUserResponseDto, TeamResponseDto, TenantAdminUserRequestDto, UserFullResponseDto } from '@api/models';
import { TenantAdminUserUpdateRequestDto } from '@api/models/tenant-admin-user-update-request-dto';
import { UserResponseDto } from '@api/models/user-response-dto';
import { TenantTeamService } from '@api/services/tenant-team.service';
import { TenantUserService } from '@api/services/tenant-user.service';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ApplicationSwitcherService } from '@shared/components/application-switcher/services/application-switcher.service';
import { CoreService } from '@shared/core/services/core.service';
import { SharedMethods } from '@shared/methods/shared.methods';
import { BlockUiService } from '@shared/services/block-ui.service';
import { NewApplication } from '@shared/types/application.types';
import { Exception } from '@shared/types/exception.types';
import { SelectOption } from '@shared/types/shared.types';
import { NewSystemUser } from '@shared/types/user.types';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { UserPageModel, UserPageUserModel } from '../types/user-page.types';

@Injectable()
export class UserService extends CoreService<any, UserPageModel> {
  constructor(
    private readonly tenantUserService: TenantUserService,
    private readonly blockUiService: BlockUiService,
    private readonly cache: NewCacheService,
    private readonly applicationSwitcherService: ApplicationSwitcherService,
    private readonly tenantTeamService: TenantTeamService,
  ) {
    super();
  }

  async init(context: any, model: UserPageModel): Promise<void> {
    super.init(context, model);
    this.initSubscriptions();

    try {
      this.m.loading = true;
      if (this.c.urlParams.id) {
        await this.setUserToModel().then(async () => {
          this.initUserTenantApplications(this.m.applications.list);
          this.c.setDefaultApplicationOptions();

          const user = (await this.cache.data.users.getAsync(this.c.urlParams.id)) as UserResponseDto;
          this.m.defaultApplicationId = user.tenant?.defaultApplicationId || null;

          if (this.m.user.profileArtifactId !== null) {
            const userProfileArtifact = await this.cache.data.artifacts.getAsync(this.m.user.profileArtifactId);
            const userProfileArtifactType = await this.cache.data.artifactTypes.getAsync(userProfileArtifact.artifactTypeId);

            this.m.userProfileUrl = {
              routerLink: `/page/${userProfileArtifactType.defaultPageId}`,
              queryParams: {
                artifactId: this.m.user.profileArtifactId,
              },
            };
          }
        });
      } else {
        this.initUserTenantApplications(this.m.applications.list);
        this.teamInit();
      }
    } finally {
      this.m.loading = false;
    }
  }

  async delete(): Promise<void> {
    this.setUiBlocked(this.m, true);
    try {
      await lastValueFrom(this.tenantUserService.userControllerDelete({ id: this.m.user.id }));
    } catch (e) {
      console.error(
        new Exception({
          name: 'DeleteUserException',
          message: 'An error occurred while deleting a user',
          originalEvent: e,
        }),
      );
    } finally {
      this.setUiBlocked(this.m, false);
    }
  }

  async update(): Promise<UserResponseDto | UserFullResponseDto | null> {
    this.setUiBlocked(this.m, true);
    try {
      const { id, email, password, confirmPassword, isTenantAdmin, isLoginDisabled, applications = [] } = this.m.user;
      const body: TenantAdminUserUpdateRequestDto = { id, isTenantAdmin, applications, isLoginDisabled };
      this.m.defaultTeamId && (body.defaultTeamId = this.m.defaultTeamId);
      this.m.defaultApplicationId && (body.defaultApplicationId = this.m.defaultApplicationId);

      if (password) {
        if (password !== confirmPassword) {
          await this.c.announcement.error('Passwords do not match');
          return null;
        }
        body.password = password;
      }

      if (email) {
        body.email = email;
      }

      const dto = await lastValueFrom(this.tenantUserService.userControllerUpdateUserByTenantAdmin({ body }));
      if (this.m.loggedUser.id === dto.id) {
        this.cache.user.update();
      }
      return dto;
    } catch (e) {
      console.error(
        new Exception({
          name: 'UpdateUserException',
          message: 'An error occurred while updating the user',
          originalEvent: e,
        }),
      );
      return null;
    } finally {
      this.setUiBlocked(this.m, false);
    }
  }

  async create(): Promise<UserResponseDto | UserFullResponseDto | null> {
    this.setUiBlocked(this.m, true);
    try {
      const { email, password, confirmPassword, isTenantAdmin, applications = [] } = this.m.user;

      if (password !== confirmPassword && !this.m.generatePassword) {
        await this.c.announcement.error('Passwords do not match');
        return null;
      }

      const body: TenantAdminUserRequestDto = {
        email,
        isTenantAdmin,
        password: !this.m.generatePassword ? password || '' : undefined,
        generatePassword: this.m.generatePassword || undefined,
        applications,
      };

      return await lastValueFrom(this.tenantUserService.userControllerCreate({ body }));
    } catch (e) {
      console.error(
        new Exception({
          name: 'CreateUserException',
          message: 'An error occurred while creating the user',
          originalEvent: e,
        }),
      );
      return null;
    } finally {
      this.setUiBlocked(this.m, false);
    }
  }

  async cancel(): Promise<void> {
    await this.c.router.navigateByUrl(`/admin/user-list`);
  }

  async resetUserPassword(): Promise<void> {
    this.setUiBlocked(this.m, true);
    try {
      await lastValueFrom(this.tenantUserService.userControllerResetPassword({ id: this.m.user.id }));
    } catch (e) {
      await this.c.announcement.error('Something went wrong during password reset');
    } finally {
      await this.c.announcement.success('Password is reseted');
      await this.c.router.navigateByUrl('/admin/user-list');
      this.setUiBlocked(this.m, false);
    }
  }

  private setUiBlocked(m: UserPageModel, blocked: boolean): void {
    m.inSavingProgress = blocked;
    blocked ? this.blockUiService.blockUi() : this.blockUiService.unblockUi();
  }

  private async setUserToModel(): Promise<void | number> {
    try {
      if (!this.m.loggedUserMeta) {
        return setTimeout(() => this.setUserToModel(), 100);
      }

      if (this.m.loggedUserMeta?.isTenantAdmin) await this.setTenantAdminToModel();
      else await this.setApplicationAdminToModel();

      await this.teamInit();
    } catch (e) {
      console.error(
        new Exception({
          name: 'SetUserToModelException',
          message: 'An error occurred while loading the user from the server',
          originalEvent: e,
        }),
      );
    }
  }

  private async setTenantAdminToModel(): Promise<void> {
    const { id, email, tenant, deleted, isLoginDisabled } = (await this.cache.data.users.getAsync(this.c.urlParams.id)) as UserFullResponseDto;

    this.m.user = new UserPageUserModel({
      id,
      email,
      isLoginDisabled,
      isTenantAdmin: !!tenant?.isAdmin,
      applications: tenant?.applications || [],
      deleted,
      profileArtifactId: tenant?.profileArtifactId || '',
      teamIds: tenant?.teamIds,
      defaultTeamId: tenant?.defaultTeamId,
    });
  }

  private async setApplicationAdminToModel(): Promise<void> {
    if (!this.applicationSwitcherService.selectedApplication) {
      await lastValueFrom(this.c.applicationSwitcherService.selectedApplication$.pipe(take(1)));
    }

    const { id, email, deleted, tenant } = (await this.cache.data.users.getAsync(this.c.urlParams.id)) as UserFullResponseDto;
    const application = tenant?.applications.find(app => app.id === this.applicationSwitcherService.selectedApplication?.id);

    this.m.user = new UserPageUserModel({
      id,
      email,
      application,
      deleted,
      profileArtifactId: tenant?.profileArtifactId,
      teamIds: tenant?.teamIds,
      defaultTeamId: tenant?.defaultTeamId,
    });
  }

  private async teamInit(): Promise<void> {
    if (!this.m.user.id) {
      const value = (this.cache.data.teams?.value as TeamResponseDto[]) || [];
      this.m.teams = value.map(dto => new SelectOption(dto.name, dto.id));

      if (!this.m.teams.length) {
        setTimeout(() => {
          this.teamInit();
        });
      }
      return;
    }

    const { everyoneTeamId } = this.m.loggedUser.tenant;
    const { teamIds, defaultTeamId } = this.m.user;

    if (!Array.isArray(teamIds) || !defaultTeamId || !everyoneTeamId) return;

    if (defaultTeamId) {
      this.m.defaultTeamId = defaultTeamId;
      teamIds.push(defaultTeamId);
    }

    everyoneTeamId && teamIds.push(everyoneTeamId);
    const filteredTeamIds = Array.from(new Set(teamIds));

    this.m.teams = (await this.cache.data.teams.getManyAsync(filteredTeamIds)).map(dto => new SelectOption(dto.name, dto.id));
  }

  private initSubscriptions(): void {
    const data = this.cache.data;

    this.c.registerSubscriptions([
      data.applications.subscribe(applications =>
        this.m.applications.setList(
          applications!.map(dto => new NewApplication(dto as ApplicationResponseDto)),
          'id',
        ),
      ),
      this.cache.user.subscribe(user => {
        this.m.loggedUser = new NewSystemUser(user as SelfUserResponseDto);
        this.m.loggedUserMeta = this.cache.userMeta;
      }),
    ]);
  }

  private initUserTenantApplications(applications: NewApplication[]): void {
    const allUserTenantApplications = applications.map(application => ({ id: application.id, isAdmin: false }));
    this.m.userTenantApplications = SharedMethods.findDistinct(allUserTenantApplications, this.m.user.applications, 'id');
  }
}
