import { Injectable } from '@angular/core';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { LinkType } from '@shared/types/link-type.types';
import { ArtifactFormItemType, ArtifactWidgetModel } from '@widgets/artifact-widget/types/artifact-widget.types';
import { AttributeFormatSettings, LinkTypeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';

@Injectable({ providedIn: 'root' })
export class ArtifactWidgetStyleCopyPastingService {
  copiedAttributeFormatSettings: AttributeFormatSettings;
  copiedLinkTypeFormatSettings: LinkTypeFormatSettings;
  copiedAttributeId: string;
  copiedLinkTypeId: string;

  copyStyle(id: string, itemType: ArtifactFormItemType, m: ArtifactWidgetModel): void {
    if (itemType === ArtifactFormItemType.attribute) {
      this.copiedAttributeId = id;
      this.copiedAttributeFormatSettings = structuredClone(m.formatsMap.attribute[id]);
      this.copiedAttributeFormatSettings.initialValue = undefined;
      this.copiedAttributeFormatSettings.value.displayMetadata = undefined;
    } else if (itemType === ArtifactFormItemType.linkType) {
      this.copiedLinkTypeId = id;
      this.copiedLinkTypeFormatSettings = structuredClone(m.formatsMap.linkType[id]);
      this.copiedAttributeFormatSettings.initialValue = undefined;
      this.copiedAttributeFormatSettings.value.displayMetadata = undefined;
    }
  }

  pasteStyle(target: AttributeFormatSettings | LinkTypeFormatSettings, targetDataType?: NewDataType): void {
    if (target instanceof AttributeFormatSettings) {
      this.pasteAttributeStyle(target, this.copiedAttributeFormatSettings, targetDataType);
    } else if (target instanceof LinkTypeFormatSettings) {
      this.pasteLinkTypeStyle(target, this.copiedLinkTypeFormatSettings);
    }
  }

  pasteStyleToAllItems(selectedItem: NewClientAttribute | LinkType, meta: LinkDirection, m: ArtifactWidgetModel): void {
    if (selectedItem instanceof NewClientAttribute && selectedItem.id) this.pasteStyleToAllAttributes(m.formatsMap.attribute[selectedItem.id], m);
    else if (selectedItem instanceof LinkType) this.pasteStyleToAllLinkTypes(m.formatsMap.linkType[selectedItem.id + '_' + meta], m);
  }

  private pasteStyleToAllAttributes(copiedStyle: AttributeFormatSettings, m: ArtifactWidgetModel): void {
    Object.keys(m.formatsMap.attribute).forEach(attrId => {
      const attribute = m.options.attributes.listMap[attrId];
      const targetDataType = attribute && m.options.dataTypes.listMap[attribute.dataTypeId];
      this.pasteAttributeStyle(m.formatsMap.attribute[attrId], copiedStyle, targetDataType);
    });
  }

  private pasteStyleToAllLinkTypes(copiedStyle: LinkTypeFormatSettings, m: ArtifactWidgetModel): void {
    Object.values(m.formatsMap.linkType).forEach(style => this.pasteLinkTypeStyle(style, copiedStyle));
  }

  private pasteAttributeStyle(target: AttributeFormatSettings, copied: AttributeFormatSettings, targetDataType?: NewDataType): void {
    const placeholder = target.value.placeholder;
    const showPlaceholder = target.value.showPlaceholder;
    const customLabel = target.label.customLabel;
    const useCustomLabel = target.label.useCustomLabel;
    const initialValue = target.initialValue;
    const displayMetadata = target.value.displayMetadata;

    Object.assign(target, structuredClone(copied));

    target.initialValue = initialValue;
    target.value.placeholder = placeholder;
    target.value.showPlaceholder = showPlaceholder;
    target.value.displayMetadata = displayMetadata;
    target.label.customLabel = customLabel;
    target.label.useCustomLabel = useCustomLabel;

    this.restoreUnchangeableSettings(target, targetDataType);
  }

  private pasteLinkTypeStyle(target: LinkTypeFormatSettings, copied: LinkTypeFormatSettings): void {
    const customLabel = target.label.customLabel;
    const showCustomLabel = target.label.useCustomLabel;
    Object.assign(target, structuredClone(copied));
    target.label.customLabel = customLabel;
    target.label.useCustomLabel = showCustomLabel;
  }

  private restoreUnchangeableSettings(target: AttributeFormatSettings, targetDataType?: NewDataType): void {
    if (!targetDataType) return;

    if (targetDataType.kind === DataTypeKind.counter) {
      target.editable = false;
    }
  }
}
