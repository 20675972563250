import { Component, Input } from '@angular/core';
import { EMAIL_KEY, IS_TENANT_ADMIN_KEY, IS_TENANT_ADMIN_LABEL } from '@shared/constants/constants';
import { CoreListComponent } from '@shared/core/components/core-list.component';
import { TableColumn } from '@shared/types/table.types';
import { NewUser, UserFull } from '@shared/types/user.types';

@Component({
  selector: 'app-user-list-table',
  templateUrl: './user-list-table.component.html',
  styleUrls: ['./user-list-table.component.scss'],
})
export class UserListTableComponent extends CoreListComponent<NewUser | UserFull> {
  @Input() columns: TableColumn[];

  IS_TENANT_ADMIN_LABEL = IS_TENANT_ADMIN_LABEL;
  IS_TENANT_ADMIN_VALUE = IS_TENANT_ADMIN_KEY;
  EMAIL_VALUE = EMAIL_KEY;

  constructor() {
    super();
  }
}
